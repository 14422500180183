import React, { Component } from 'react'
export default class Twitter extends Component {
    render () {
        return (
            <div className="container">
            <link rel="stylesheet" href="http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"></link>
              <div className="twitter">
              <h3><i className="fa fa-twitter fa-lg cyan color"></i> Twitter <span><a href="https://twitter.com/ARIA_access" target="_blank" className="twitterName">@aria_access</a></span></h3>
              </div>
            </div>
            
          );
    }
}