import React, { Component } from 'react'
import arialogo from '../images/ARIA.png';
import ariaidsslogo from '../images/ARIA-IDSS.png';

export default class Status extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: []
      };
      this.statuses = {
        0: 'paused',
        1: 'checking',
        "OK": 'live',
        "WARNING": 'seems-down',
        "CRITICAL": 'timeout'
      };
      this.logos = {
        "ARIA": arialogo,
        "ARIA-IDSS": ariaidsslogo
      }
    }
    componentDidMount() {
      // we are getting information from EOSC ARGO monitoring platform, but in order to hide the secret key we use AWS API Gateway
      var url = "https://e66icevf1e.execute-api.eu-west-2.amazonaws.com/Production/getServices";
      var date = new Date();
      var end_time = date.toJSON();
      var start_time = new Date(date.getTime() - (1000*60)).toJSON();
      url += "?start_time="+start_time+"&end_time="+end_time;

      fetch(url, {
        method: 'GET',
        origin : "*",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then(res => res.json())
        .then(
          (result) => {
            let services = result.groups[0].services;
            if (services.length === 3) {
              services = services.splice(1,2);
            }
            for (let i = 0; i < services.length; i++) {
              if (services[i].name === "eu.eosc.keycloak") {
                services[i].friendly_name = "ARIA-IDSS";
                services[i].url = "https://instruct-eric.org/api/keycloak/v1/authorise/?return_url=https%3A%2F%2Finstruct-eric.org%2F";
              } else {
                services[i].friendly_name = "ARIA";
                services[i].url = "https://instruct-eric.org";
              }
              services[i].status = services[i].statuses[1].value
            }
            this.setState({
              isLoaded: true,
              items: services
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else if (items == null) {
        return <div>Error: nothing returned from ajax.</div>
      } else {
        return (
            <div>
                <div className="timecode">Last checked @ <b><i>{new Date().toLocaleString() + ""}</i></b></div>
                <div className="container">
                {items.map((item, index) => (
                    <div className="status-container" key={index}>
                        <a href={item.url} className="hover"><div className="row logo">                        
                            <img width="100" height="100" src={this.logos[item.friendly_name]} />
                        </div></a>
                        <div className="row">
                            <h2>{item.friendly_name}</h2>
                        </div>       
                        <div className="row" id={this.statuses[item.status]}>
                            <h4>Status: {this.statuses[item.status]}</h4>
                        </div>             
                    </div>
                ))}
                </div>
            </div>
        );
      }
    }
  }
