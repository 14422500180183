import React, { Component } from 'react'

export default class Header extends Component {
    render () {
        return (
            <div className="App">
                <header>
                    <h1>ARIA Status Page</h1>
                </header>
                <br></br>
            </div>
        )
    }
}