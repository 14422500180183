import React, { Component } from 'react'
import './App.css';
import Header from './components/header'
import Status from './components/status'
import Twitter from './components/twitter'

function App() {
  return (
    <div>
      <Header></Header>
      <Status></Status>
      <Twitter></Twitter>
    </div>

  );
}

export default App;
